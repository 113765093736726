<template>

<v-container class="home_container page_container">
  
<v-img :src="hero_img"></v-img>
<h2 class="page_title">Error: 404</h2>
<div class="content">
    <p>The page you're looking for couldn't be found. Please double check that the URL is correct, and contact us if the problem persists.</p>
</div>
<projects project_block_title="Past Projects" post_limit="3"/>
</v-container>

</template>

<script>
import projects from "@/components/projects.vue"
export default {
  components: {
    projects
  },  
};
</script>
<style>
</style>
